import React, { Suspense, lazy } from 'react';
import { Navigate } from "react-router-dom"

// Profile
//import UserProfile from "../pages/Authentication/user-profile"
const UserProfile = lazy(() => import('../pages/Authentication/user-profile'));

// Authentication related pages
//import Login from "../pages/Authentication/Login"
//import Logout from "../pages/Authentication/Logout"
//import ImportExcel from "../pages/Dashboard/ImportExcel"
//import PublicHome from "../pages/Home/PublicHome"
//import RegHome from "../pages/Registration/RegHome"
//import ConfigureNewPrediction from "../pages/Dashboard/ConfigureNewPrediction"
//import Dashboard from "../pages/Dashboard/index"

const Login = lazy(() => import('../pages/Authentication/Login'));
const Logout = lazy(() => import('../pages/Authentication/Logout'));
const ImportExcel = lazy(() => import('../pages/Dashboard/ImportExcel'));
const ImportUsersToClubs = lazy(() => import('../pages/Dashboard/ImportUsersToClubs'));
const ImportTeamsForTournament = lazy(() => import('../pages/Dashboard/ImportTeamsForTournament'));
const ImportMatchesForTournament = lazy(() => import('../pages/Dashboard/ImportMatchesForTournament'));

const PublicHome = lazy(() => import('../pages/Home/PublicHome'));
const RegisterHome = lazy(() => import('../pages/Home/RegisterHome'));
const RegHome = lazy(() => import('../pages/Registration/RegHome'));
const DeleteDataHome = lazy(() => import('../pages/Registration/DeleteDataHome'));

const ConfigureNewPrediction = lazy(() => import('../pages/Dashboard/ConfigureNewPrediction'));
const Dashboard = lazy(() => import('../pages/Dashboard/index'));
const ViewAlllRegistrations = lazy(() => import('../pages/Home/ViewAlllRegistrations'));
const InitialiseWhatsApp = lazy(() => import('../pages/Dashboard/InitialiseWhatsApp'));
const GroupInvite = lazy(() => import('../pages/Home/GroupInvite'));

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

  // //profile
  { path: "/profile", component: <UserProfile/> },
  { path: "/import-contacts", component: <ImportExcel /> },
  { path: "/import-users", component: <ImportUsersToClubs /> },
  { path: "/import-teams", component: <ImportTeamsForTournament /> },
  { path: "/import-matches", component: <ImportMatchesForTournament /> },
  { path: "/configure", component: <ConfigureNewPrediction /> },
  { path: "/init-wp", component: <InitialiseWhatsApp /> },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   { path: "/", exact: true, component: < Navigate to="/home" />},
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/test", component: <RegHome /> },
  { path: "/joingroup/:id", component: <GroupInvite /> },
  { path: "/removemydata", component: <DeleteDataHome /> },
  
  { path: "/register-bkp", component: <RegisterHome /> },
  { path: "/clashofclans2023", component: <PublicHome /> },
  { path: "/registrations-bkp", component: <ViewAlllRegistrations /> },
  { path: "/", exact: true, component: < Navigate to="/clashofclans2023" />},
]

export { authProtectedRoutes, publicRoutes }

