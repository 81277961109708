import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {

  const [dashboard, setdashboard] = useState(false);
  const [reports, setreports] = useState(false);
  const [settings, setSettings] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [payments, setPayments] = useState(false);

  const [userRole, setUserRole] = useState('')

  useEffect(() => {
    if (localStorage.getItem("scoreMasterAthUser")) {
      const obj = JSON.parse(localStorage.getItem("scoreMasterAthUser"))
      if(obj.role){
        setUserRole(obj.role)
      }
    }
  }, [])

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }


  return (
    <React.Fragment>
    <div className="topnav">
      <div className="">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={e => {
                    //e.preventDefault();
                    setdashboard(!dashboard);
                  }}
                  to="/dashboard"
                >
                  <i className="bx bx-home-circle me-2"></i>
                  {props.t("Dashboard")} {props.menuOpen}
                  {/*<div className="arrow-down"></div>*/}
                </Link>
                {/* 
                <div
                  className={classname("dropdown-menu", { show: dashboard })}
                >
                  <Link to="/dashboard" className="dropdown-item">
                    {props.t("Default")}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t("Saas")}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t("Crypto")}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t("Blog")}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t("Jobs")}
                  </Link>
                </div>
                */}
              </li>

              {
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={e => {
                    setreports(!reports);
                    //e.preventDefault();
                    
                  }}
                  to="/contacts"
                >
                  <i className="bx bxs-report me-2"></i>
                  {props.t("Contacts")} {props.menuOpen}
                </Link>
                
              </li>
               }

              {
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={e => {
                    setPayments(!payments);
                    //e.preventDefault();
                    
                  }}
                  to="/init-wp"
                >
                  <i className="mdi mdi-whatsapp me-2" />
                  {props.t("Message")} {props.menuOpen}
                </Link>
                
              </li>
               }

              {
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={e => {
                    setSettings(!settings);
                    e.preventDefault();
                    
                  }}
                  to="/import-contacts"
                >
                  <i className="bx bx-lock-alt me-2"></i>
                  {props.t("Settings")} {props.menuOpen}
                  {<div className="arrow-down"></div>}
                </Link>
                
                <div
                  className={classname("dropdown-menu", { show: settings })}
                >
                  <Link to="/import-contacts" className="dropdown-item">
                    {props.t("Import")}
                  </Link>
                </div>
                
              </li>
               }

            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
