import PropTypes from 'prop-types';
import React, { Suspense } from "react"
import { Routes, Route } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// init firebase backend
//initFirebaseBackend(firebaseConfig);

const App = () => {


  const Layout = HorizontalLayout;

  return (
    <React.Fragment>
        <Suspense fallback={<div className="popup-overlay"><div className="popup-content"><i className="bx bx-loader bx-spin font-size-24 align-middle" /></div></div>}>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <NonAuthLayout>
                    {route.component}
                  </NonAuthLayout>
                }
                key={idx}
                exact={true}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>}
                key={idx}
                exact={true}
              />
            ))}
          </Routes>
        </Suspense>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;